import Cookies from "js-cookie";

export const logoutUser = async () => {
  try {
    Object.keys(Cookies.get()).forEach(cookieName => {
      Cookies.remove(cookieName);
    });

    console.log('Logout successful: All cookies cleared.');
    return true;
  } catch (error) {
    console.error("Logout Error:", error);
    return false;
  }
};