import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { Mail, AlertCircle, Plus, Clock } from 'react-feather';
import { UserMetrics } from '../components/userMetrics';

interface UnreadEmails {
  important: number;
  other: number;
}

interface ActionItem {
  text: string;
  importance: 'high' | 'new';
}

interface SuggestedMeeting {
  title: string;
  time: string;
}


const Dashboard: React.FC = () => {
  // Mock data
  const unreadEmails: UnreadEmails = {
    important: 12,
    other: 43
  };


  const actionItems: ActionItem[] = [
    { text: "Review Q3 Financial Report", importance: "high" },
    { text: "Prepare for TechCorp partnership meeting", importance: "high" },
    { text: "Follow up on Project Phoenix timeline", importance: "high" },
    { text: "Review new marketing strategy proposal", importance: "new" },
    { text: "Schedule team building event", importance: "new" },
  ];

  const suggestedMeetings: SuggestedMeeting[] = [
    { title: "Weekly Team Sync", time: "10:00 AM" },
    { title: "Client Presentation: HealthTech Solutions", time: "2:00 PM" },
    { title: "1:1 with Sarah Johnson", time: "4:30 PM" },
  ];

  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
      <UserMetrics />

      {/* Today's Unread Emails */}
      <motion.div
        className="bg-gradient-to-br from-white/30 to-white/0 p-6 rounded-lg shadow-lg backdrop-blur-sm"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.1 }}
      >
        <h2 className="text-xl font-semibold text-white mb-4">Today's Unread - Emails</h2>
        <div className="space-y-3">
          <div className="flex items-center justify-between">
            <span className="text-lg text-white">Important:</span>
            <span className="text-2xl font-bold text-xylo-pink">{unreadEmails.important}</span>
          </div>
          <div className="flex items-center justify-between">
            <span className="text-lg text-white">Other:</span>
            <span className="text-2xl font-bold text-xylo-pink">{unreadEmails.other}</span>
          </div>
        </div>
        <Link to="/emails" className="mt-4 inline-flex items-center bg-xylo-purple text-white px-4 py-2 rounded-full hover:bg-opacity-80 transition-colors">
          <Mail size={18} className="mr-2" />
          View Emails
        </Link>
      </motion.div>

      {/* Today's Action List */}
      <motion.div
        className="lg:col-span-2 bg-gradient-to-br from-white/30 to-white/0 p-6 rounded-lg shadow-lg backdrop-blur-sm"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
      >
        <h2 className="text-xl font-semibold text-white mb-4">Today's Action List</h2>
        <div className="space-y-4">
          <div>
            <h3 className="text-lg font-medium text-white mb-2">High Importance</h3>
            <ul className="space-y-2">
              {actionItems.filter(item => item.importance === "high").map((item, index) => (
                <li key={index} className="flex items-center space-x-2 text-white">
                  <AlertCircle size={18} className="text-red-500" />
                  <span>{item.text}</span>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3 className="text-lg font-medium text-white mb-2">New to Review</h3>
            <ul className="space-y-2">
              {actionItems.filter(item => item.importance === "new").map((item, index) => (
                <li key={index} className="flex items-center space-x-2 text-white">
                  <Plus size={18} className="text-yellow-500" />
                  <span>{item.text}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <Link to="/tasks" className="mt-4 inline-flex items-center bg-xylo-purple text-white px-4 py-2 rounded-full hover:bg-opacity-80 transition-colors">
          <AlertCircle size={18} className="mr-2" />
          View All Tasks
        </Link>
      </motion.div>

      {/* Suggested Meetings */}
      <motion.div
        className="bg-gradient-to-br from-white/30 to-white/0 p-6 rounded-lg shadow-lg backdrop-blur-sm"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.3 }}
      >
        <h2 className="text-xl font-semibold text-white mb-4">Suggested Meetings</h2>
        <ul className="space-y-3">
          {suggestedMeetings.map((meeting, index) => (
            <li key={index} className="flex items-center justify-between text-white bg-white/10 p-3 rounded-lg">
              <span>{meeting.title}</span>
              <span className="text-xylo-pink flex items-center">
                <Clock size={16} className="mr-1" />
                {meeting.time}
              </span>
            </li>
          ))}
        </ul>
      </motion.div>
    </div>
  );
};

export default Dashboard;
