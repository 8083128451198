import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import {
  Home,
  MessageSquare,
  Calendar,
  Users,
  BarChart2,
  FileText,
  CheckSquare,
  Mail,
  Edit3,
  LogOut
} from 'react-feather';
import Dashboard from './screens/Dashboard';
import EmailPage from './screens/EmailPage';
import TemplatePage from './screens/TemplatePage';
import EmailTemplatePage from './screens/templates/EmailTemplate';
import DocumentTemplatePage from './screens/templates/DocumentTemplate';
import PhrasesPage from './screens/TemplatePhrasesPage';
import RelationshipPage from './screens/RelationshipPage';
import AIComposePage from './screens/AIComposePage';
import DocumentComposer from './screens/compose/DocumentComposer';
import PlannerPage from './screens/PlannerPage';
import TasksPage from './screens/TasksPage';
import AiDraftPage from './screens/AiDraftPage';
import LoginPage from './screens/LoginPage';
import Tooltip from './components/Tooltip';
import { StateManagerProvider, useStateManager } from './handlers/statemanager';
import { logoutUser } from './handlers/auth';

interface NavItemProps {
  item: {
    name: string;
    icon: React.ComponentType<{ size?: number | string }>;
    path: string;
  };
}

const NavItemComponent: React.FC<NavItemProps> = ({ item }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  const handleMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    const rect = event.currentTarget.getBoundingClientRect();
    setTooltipPosition({
      x: rect.right + 10,
      y: rect.top + rect.height / 2,
    });
    setShowTooltip(true);
  };

  return (
    <div
      className="relative"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={() => setShowTooltip(false)}
    >
      <motion.div
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.95 }}
      >
        <Link to={item.path} className="text-white hover:text-xylo-pink transition-colors duration-200">
          <item.icon size={24} />
        </Link>
      </motion.div>
      <Tooltip show={showTooltip} position={tooltipPosition}>
        {item.name}
      </Tooltip>
    </div>
  );
};

const Header: React.FC = () => {
  const { state, logout } = useStateManager();
  const navigate = useNavigate();

  const handleNewUnread = () => {
    navigate('/emails');
  };

  const handleLogout = async () => {
    const logoutSuccessful = await logoutUser();
    if (logoutSuccessful) {
      logout(); // This resets the client-side state
      navigate('/login');
    } else {
      console.error('Logout failed');
      // Optionally, show an error message to the user
    }
  };

  return (
    <header className="bg-xylo-purple bg-opacity-90 backdrop-blur-md p-4 flex justify-between items-center">
      <div className="flex items-center">
        <img src="/xylologo.png" alt="Xylo Logo" className="h-10 w-auto" />
      </div>
      <div className="flex items-center space-x-4">
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="bg-xylo-pink text-white px-4 py-2 rounded-full font-medium"
          onClick={handleNewUnread}
        >
          New Unread
        </motion.button>
        {state.userDetails && (
          <>
            <motion.div
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              className="w-10 h-10 bg-white rounded-full flex items-center justify-center text-xylo-purple font-bold"
            >
              {state.userDetails.name?.charAt(0).toUpperCase()}
            </motion.div>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="text-white"
              onClick={handleLogout}
            >
              <LogOut size={24} />
            </motion.button>
          </>
        )}
      </div>
    </header>
  );
};

const AppContent: React.FC = () => {
  const { state } = useStateManager();

  const navItems = [
    { name: 'Dashboard', icon: Home, path: '/' },
    { name: 'Email', icon: Mail, path: '/emails' },
    { name: 'AI Compose', icon: MessageSquare, path: '/composer' },
    { name: 'AI Draft', icon: Edit3, path: '/draft' },
    { name: 'Planner', icon: Calendar, path: '/planner' },
    { name: 'Relationship', icon: Users, path: '/contacts' },
    { name: 'Analytics', icon: BarChart2, path: '/analytics' },
    { name: 'Template', icon: FileText, path: '/templates' },
    { name: 'Tasks Management', icon: CheckSquare, path: '/tasks' },
  ];

  if (!state.userId) {
    return <LoginPage />;
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-xylo-purple to-dark-bg text-white flex">
      <nav className="w-20 bg-xylo-purple bg-opacity-90 backdrop-blur-md flex flex-col items-center py-8 space-y-8">
        {navItems.map((item) => (
          <NavItemComponent key={item.name} item={item} />
        ))}
      </nav>
      <div className="flex-1 flex flex-col">
        <Header />
        <main className="flex-1 p-8 overflow-auto">
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/emails" element={<EmailPage />} />
            <Route path="/composer" element={<AIComposePage />} />
            <Route path="/composer/document" element={<DocumentComposer />} />
            <Route path="/draft" element={<AiDraftPage />} />
            <Route path="/planner" element={<PlannerPage />} />
            <Route path="/templates" element={<TemplatePage />} />
            <Route path="/templates/email" element={<EmailTemplatePage />} />
            <Route path="/templates/document" element={<DocumentTemplatePage />} />
            <Route path="/templates/phrases" element={<PhrasesPage />} />
            <Route path="/contacts" element={<RelationshipPage />} />
            <Route path="/tasks" element={<TasksPage />} />
            {/* Add route for Analytics when it's created */}
            {/* <Route path="/analytics" element={<AnalyticsPage />} /> */}
          </Routes>
        </main>
      </div>
    </div>
  );
};

const App: React.FC = () => {
  return (
    <StateManagerProvider>
      <Router>
        <AppContent />
      </Router>
    </StateManagerProvider>
  );
};

export default App;
