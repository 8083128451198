import axios from 'axios';

import * as objFormatter from '../_helpers/object-formatter';
import { authCookieStore } from '../_helpers/cookie-store';
import config from '../config';

export const endpoints = {
  userGetId: '/api/users/id/',
  userGet: '/user-data/',
  userPostContactDetail: '/users/contact_detail/',
  userDeleteContactDetail: '/users/contact/delete/',
  userPostContactDetailTrais: '/users/contact_detail/user-selected-traits/',
  userPostSessionKey: '/email-ui-session/',
} as const;

export const service = axios.create({
  baseURL: `${config.backendURL}`,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const setHeader = (key: string, value: string) => {
  console.log({ key, value });

  if (value) {
    service.defaults.headers.common[key] = value;
  }

}

service.interceptors.request.use((config) => {
  const token = authCookieStore.getToken();

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

// @todo working on this
// service.interceptors.response.use((config) => {
//   // refrsh token

// });

service.interceptors.request.use((config) => {
  if (config.data) {
    // console.log({ configData: JSON.stringify(config.data) });
    // eslint-disable-next-line no-param-reassign
    config.data = objFormatter.toSnakeCase(config.data);
  }

  // console.log({ config: JSON.stringify(config) });

  return config;
});

service.interceptors.response.use((response) => {
  if (response.data) {
    // console.log({ response_data: JSON.stringify(response.data) });
    response.data = objFormatter.toCamelCase(response.data);
  }

  return response;
}, (error) => Promise.reject(error));
