import mapKeys from 'lodash/mapKeys';
import camelCase from 'lodash/camelCase';
import snakeCase from 'lodash/snakeCase';
import isArray from 'lodash/isArray';
import isFunction from 'lodash/isFunction';
import isObject from 'lodash/isObject';
import mapValues from 'lodash/mapValues';

export const toCamelCase = <T>(obj: any): T => {
  if (isArray(obj)) {
    return obj.map(toCamelCase) as T;
  }

  if (isObject(obj) && !isFunction(obj)) {
    return mapValues(
      mapKeys(obj, (_, key) => camelCase(key)),
      toCamelCase,
    ) as T;
  }

  return obj as T;
};

export const toSnakeCase = <T>(obj: any) => {
  if (isArray(obj)) {
    return obj.map(toCamelCase) as T;
  }

  if (isObject(obj) && !isFunction(obj)) {
    return mapValues(
      mapKeys(obj, (_, key) => snakeCase(key)),
      toCamelCase,
    ) as T;
  }

  return obj as T;
};
