import { useState, useCallback } from 'react';
import { jwtDecode } from 'jwt-decode';
import Cookies from 'js-cookie';

export function useAccessToken() {
  
  const [accessToken, setAccessToken] = useState<string | null>(Cookies.get('access_token') || null);
  const [refreshToken, setRefreshToken] = useState<string | null>(Cookies.get('refresh_token') || null);

  const login = useCallback((newAccessToken: string, newRefreshToken: string) => {
    setAccessToken(newAccessToken);
    setRefreshToken(newRefreshToken);
    Cookies.set('access_token', newAccessToken, { secure: true, sameSite: 'strict' });
    Cookies.set('refresh_token', newRefreshToken, { secure: true, sameSite: 'strict' });
  }, []);

  const logout = useCallback(() => {
    setAccessToken(null);
    setRefreshToken(null);
    Cookies.remove('access_token');
    Cookies.remove('refresh_token');
  }, []);

  const check = useCallback(async (): Promise<string | null> => {

    if (accessToken && !isExpired(accessToken)) {
      console.log('Using existing access token');
      return accessToken;
    }

    const storedAccessToken = Cookies.get('access_token');
    console.log('Stored access token:', storedAccessToken);
    
    if (storedAccessToken && !isExpired(storedAccessToken)) {
      console.log('Using stored access token');
      setAccessToken(storedAccessToken);
      return storedAccessToken;
    }

    let newRefreshToken = refreshToken || Cookies.get('refresh_token');
    console.log('New refresh token:', newRefreshToken);

    if (newRefreshToken) {
      console.log('Attempting to refresh access token');
      const newAccessToken = await refreshAccessToken(newRefreshToken);
      if (newAccessToken) {
        console.log('Successfully refreshed access token');
        setAccessToken(newAccessToken);
        return newAccessToken;
      }
    }

    console.warn("No refresh nor access token, logging out");
    logout();
    return null;
  }, [accessToken, refreshToken, logout]);

  return { check, accessToken, login, logout };
}

function isExpired(accessToken: string): boolean {
  if (!accessToken) {
    return true;
  }
  try {
    const decodedToken = jwtDecode<{ exp?: number }>(accessToken);
    const exp = decodedToken.exp;

    if (exp && exp < Date.now() / 1000) {
      return true;
    }
    return false;
  } catch (error) {
    console.error("Error decoding token:", error);
    return true;
  }
}

async function refreshAccessToken(refreshToken: string): Promise<string | null> {
  try {
    // Implement your token refresh logic here
    // This is a placeholder implementation
    const response = await fetch('/api/refresh-token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ refreshToken }),
    });

    if (response.ok) {
      const data = await response.json();
      return data.accessToken;
    }
  } catch (error) {
    console.error("Error refreshing access token:", error);
  }

  return null;
}
