import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FileText, ChevronDown, ChevronUp } from 'react-feather';

interface Template {
  id: string;
  name: string;
  description: string;
  inputProperties: { name: string; type: string; description: string }[];
}

const mockTemplates: Template[] = [
  {
    id: '1',
    name: 'Business Proposal',
    description: 'A comprehensive business proposal template',
    inputProperties: [
      { name: 'companyName', type: 'string', description: 'Name of the company' },
      { name: 'projectTitle', type: 'string', description: 'Title of the proposed project' },
      { name: 'budget', type: 'number', description: 'Proposed budget in USD' },
      { name: 'timeline', type: 'string', description: 'Proposed project timeline' },
    ],
  },
  {
    id: '2',
    name: 'Meeting Minutes',
    description: 'Template for recording meeting minutes',
    inputProperties: [
      { name: 'meetingDate', type: 'date', description: 'Date of the meeting' },
      { name: 'attendees', type: 'string', description: 'List of attendees (comma-separated)' },
      { name: 'agenda', type: 'string', description: 'Meeting agenda items' },
    ],
  },
];

const DocumentComposer: React.FC = () => {
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(null);
  const [isTemplateListOpen, setIsTemplateListOpen] = useState(false);
  const [inputValues, setInputValues] = useState<Record<string, string>>({});
  const [generatedDocument, setGeneratedDocument] = useState<string | null>(null);

  const handleTemplateSelect = (template: Template) => {
    setSelectedTemplate(template);
    setIsTemplateListOpen(false);
    setInputValues({});
    setGeneratedDocument(null);
  };

  const handleInputChange = (propertyName: string, value: string) => {
    setInputValues((prev) => ({ ...prev, [propertyName]: value }));
  };

  const handleGenerate = async () => {
    const generatedContent = `Generated Document:
Template: ${selectedTemplate?.name}
${Object.entries(inputValues)
  .map(([key, value]) => `${key}: ${value}`)
  .join('\n')}`;

    setGeneratedDocument(generatedContent);
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-xylo-purple to-dark-bg text-white p-8">
      <h1 className="text-4xl font-bold mb-8">Document Composer</h1>

      <div className="mb-8">
        <div className="relative">
          <button
            className="w-full bg-white/10 p-4 rounded-lg flex justify-between items-center"
            onClick={() => setIsTemplateListOpen(!isTemplateListOpen)}
          >
            <span>{selectedTemplate ? selectedTemplate.name : 'Select a template'}</span>
            {isTemplateListOpen ? <ChevronUp /> : <ChevronDown />}
          </button>
          {isTemplateListOpen && (
            <motion.div
              initial={{ opacity: 0, y: -10 }}
              animate={{ opacity: 1, y: 0 }}
              className="absolute z-10 w-full mt-2 bg-dark-surface rounded-lg shadow-lg"
            >
              {mockTemplates.map((template) => (
                <button
                  key={template.id}
                  className="w-full text-left p-4 hover:bg-white/10 transition-colors"
                  onClick={() => handleTemplateSelect(template)}
                >
                  <div className="font-semibold">{template.name}</div>
                  <div className="text-sm text-gray-400">{template.description}</div>
                </button>
              ))}
            </motion.div>
          )}
        </div>
      </div>

      {selectedTemplate && (
        <div className="bg-white/10 p-6 rounded-lg mb-8">
          <h2 className="text-2xl font-semibold mb-4">{selectedTemplate.name}</h2>
          <p className="text-gray-300 mb-6">{selectedTemplate.description}</p>
          <div className="space-y-4">
            {selectedTemplate.inputProperties.map((property) => (
              <div key={property.name}>
                <label className="block text-sm font-medium text-gray-300 mb-1">
                  {property.name}
                </label>
                <input
                  type={property.type === 'number' ? 'number' : 'text'}
                  className="w-full bg-white/5 text-white rounded-md px-3 py-2"
                  placeholder={property.description}
                  value={inputValues[property.name] || ''}
                  onChange={(e) => handleInputChange(property.name, e.target.value)}
                />
              </div>
            ))}
          </div>
          <motion.button
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="mt-6 bg-xylo-pink text-white px-6 py-3 rounded-full font-medium inline-flex items-center"
            onClick={handleGenerate}
          >
            <FileText size={20} className="mr-2" /> Generate Document
          </motion.button>
        </div>
      )}

      {generatedDocument && (
        <div className="bg-white/10 p-6 rounded-lg">
          <h2 className="text-2xl font-semibold mb-4">Generated Document</h2>
          <pre className="whitespace-pre-wrap bg-white/5 p-4 rounded-lg">{generatedDocument}</pre>
        </div>
      )}
    </div>
  );
};

export default DocumentComposer;
