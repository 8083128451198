const removeBackticks = (markdown: string): string => {
  markdown = markdown.replace(/`(.*?)`/g, '$1');
  return markdown;
}

export const stripMarkdown = (markdown: string): string => {
  if (!markdown) {
    return '';
  }
  markdown = removeBackticks(markdown);
  return markdown;
}
