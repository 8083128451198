import { service } from './service';
import config from '../config';

type SessionResponse = {
  sessionId: string;
  expiresAt: string;
};

export const getNewSessionId = async (): Promise<SessionResponse> => {
  const url = `${config.backendURL}/email-agent/generate-session-id/`;
  const { data } = await service.get<SessionResponse>(url);
  return data;
}
