import React from 'react';

import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { getUserMetrics, UserMetricState } from '../handlers/userMetricsApi';

interface MetricCardProps {
  title: string;
  value: string;
  subtext: string;
}

const MetricCard: React.FC<MetricCardProps> = ({ title, value, subtext }) => (
  <div className="bg-white/10 p-4 rounded-lg">
    <h3 className="text-lg font-medium text-white mb-2">{title}</h3>
    <div className="text-2xl font-bold text-xylo-pink">{value}</div>
    <div className="text-sm text-gray-300 mt-1">{subtext}</div>
  </div>
);

export const UserMetrics = () => {
  const [userMetrics, setUserMetrics] = useState<UserMetricState | null>(null);
  useEffect(() => {
    console.log("Fetching user metrics...");
    getUserMetrics().then(data => {
      if (!data) return
      setUserMetrics(data);
      console.debug(data)
    });
  }, []);

  return (
    <motion.div
      className="lg:col-span-2 bg-gradient-to-br from-white/30 to-white/0 p-6 rounded-lg shadow-lg backdrop-blur-sm"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <h2 className="text-xl font-semibold text-white mb-4">Communication Metrics</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {userMetrics && (
          <>
            <MetricCard
              title="Red Flags Detected (last 24h)"
              value={`${userMetrics.last_24h.red_flag_count}`}
              subtext={`${userMetrics.last_24h.red_flag_per_email_average} per email on average`}
            />
            <MetricCard
              title="Red Flags Detected (last 7 days)"
              value={`${userMetrics.last_week.red_flag_count}`}
              subtext={`${userMetrics.last_week.red_flag_per_email_average} per email on average`}
            />
            <MetricCard
              title="Improvements Suggested (last 24h)"
              value={`${userMetrics.last_24h.improvement_count}`}
              subtext={`${userMetrics.last_24h.improvement_per_email_average} per email on average`}
            />
            <MetricCard
              title="Improvements Suggested (last 7 days)"
              value={`${userMetrics.last_week.improvement_count}`}
              subtext={`${userMetrics.last_week.improvement_per_email_average} per email on average`}
            />
          </>
        )}
      </div>
    </motion.div>
  );
};
