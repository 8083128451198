import React, { useState, useEffect } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Flag, Clock, AlertCircle, ChevronDown, ChevronUp, ExternalLink, Calendar, CheckSquare, Filter } from 'react-feather';
import { fetchEmails } from '../emailService';
import { Link } from 'react-router-dom';

interface Email {
  id: string;
  subject: string;
  sender: string;
  summary: string;
  body: string;
  threadSummary?: string;
  priority: 'important' | 'other';
  flag?: 'urgent' | 'review';
  requiresResponse: boolean;
  hasMeetingRequest: boolean;
  hasActionItems: boolean;
  readingTime: number;
  responseTimeCategory: string;
}

const EmailPage: React.FC = () => {
  const [emails, setEmails] = useState<Email[]>([]);
  const [showOtherEmails, setShowOtherEmails] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [filter, setFilter] = useState('all');
  const [totalActions, setTotalActions] = useState(0);
  const [expandedEmailId, setExpandedEmailId] = useState<string | null>(null);

  useEffect(() => {
    const loadEmails = async () => {
      try {
        const fetchedEmails = await fetchEmails();
        console.log('Fetched Emails:', fetchedEmails);
        setEmails(fetchedEmails);
        setTotalActions(fetchedEmails.filter(email => email.hasActionItems).length);
      } catch (err) {
        setError(err instanceof Error ? err.message : String(err));
      }
    };

    loadEmails();
  }, []);

  const IconWithTooltip: React.FC<{ icon: React.ElementType; title: string;[key: string]: any }> = ({ icon: Icon, title, ...props }) => (
    <div className="relative group">
      <Icon {...props} />
      <div className="absolute z-10 bg-xylo-purple text-white text-xs rounded-md px-3 py-2 bottom-full left-1/2 transform -translate-x-1/2 -translate-y-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 shadow-lg whitespace-nowrap">
        {title}
        <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 rotate-45 w-2 h-2 bg-xylo-purple"></div>
      </div>
    </div>
  );

  const getFlagIcon = (flag?: string) => {
    switch (flag) {
      case 'urgent':
        return <IconWithTooltip icon={AlertCircle} size={16} className="text-alert" title="Urgent email" />;
      case 'review':
        return <IconWithTooltip icon={Clock} size={16} className="text-gray-light" title="Review required" />;
      default:
        return null;
    }
  };

  const EmailSummary: React.FC<{ email: Email }> = ({ email }) => {
    const isExpanded = expandedEmailId === email.id;

    return (
      <motion.div
        className={`bg-gradient-to-r from-white/10 to-white/5 p-4 sm:p-6 rounded-lg mb-4 shadow-lg ${isExpanded ? 'border-l-4 border-xylo-pink' : 'cursor-pointer hover:from-white/15 hover:to-white/10 transition-all duration-300'}`}
        onClick={() => setExpandedEmailId(isExpanded ? null : email.id)}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: -20 }}
        transition={{ duration: 0.3 }}
      >
        <div className="flex flex-col sm:flex-row justify-between items-start mb-3">
          <h3 className="text-base sm:text-lg font-semibold text-white mb-2 sm:mb-0">{email.subject}</h3>
          <div className="flex items-center space-x-2 sm:space-x-3">
            {email.requiresResponse && <IconWithTooltip icon={Flag} size={18} className="text-alert" title="Response required" />}
            {email.hasMeetingRequest && <IconWithTooltip icon={Calendar} size={18} className="text-gray-light" title="This email requests a meeting" />}
            {email.hasActionItems && (
              <Link to="/tasks" onClick={(e) => e.stopPropagation()}>
                <IconWithTooltip icon={CheckSquare} size={18} className="text-gray-light" title="This email has required actions" />
              </Link>
            )}
            {getFlagIcon(email.flag)}
          </div>
        </div>
        <p className="text-sm text-gray-light mb-2">From: <span className="text-white">{email.sender}</span></p>

        {/* Always show the summary */}
        <div className="bg-xylo-purple/20 p-4 rounded-lg mb-4">
          <h4 className="text-md font-semibold text-white mb-2">Summary</h4>
          <p className="text-sm text-gray-light">{email.summary}</p>
        </div>

        {isExpanded && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            {email.threadSummary && (
              <div className="bg-white/5 p-4 rounded-lg mb-4">
                <h4 className="text-md font-semibold text-white mb-2">Thread Summary</h4>
                <p className="text-sm text-gray-light">{email.threadSummary}</p>
              </div>
            )}
            <div className="bg-white/10 p-4 rounded-lg">
              <h4 className="text-md font-semibold text-white mb-2">Email Body</h4>
              <p className="text-sm text-gray-light whitespace-pre-wrap">{email.body}</p>
            </div>
          </motion.div>
        )}
        <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mt-4">
          <a
            href={`https://outlook.office.com/mail/inbox/${email.id}`}
            target="_blank"
            rel="noopener noreferrer"
            className="inline-flex items-center text-white hover:text-gray-light transition-colors duration-300 mb-2 sm:mb-0"
            onClick={(e) => e.stopPropagation()}
          >
            Reply in Outlook <ExternalLink size={14} className="ml-1" />
          </a>
          <span className="text-sm text-gray-light">
            {email.readingTime} min read • {email.requiresResponse ? 'Needs response' : 'Informational'}
          </span>
        </div>
        {isExpanded && (
          <button
            className="mt-4 text-white hover:text-gray-light transition-colors duration-300"
            onClick={(e) => {
              e.stopPropagation();
              setExpandedEmailId(null);
            }}
          >
            Collapse
          </button>
        )}
      </motion.div>
    );
  };

  const filteredEmails = emails.filter(email => {
    if (filter === 'internal') {
      return email.sender.endsWith('@optum.com');
    } else if (filter === 'frequent') {
      // This is a placeholder. In a real app, you'd implement logic to determine frequent correspondents.
      return true;
    } else if (['responseNow', 'responseToday', 'responseTomorrow', 'responseThisWeek'].includes(filter)) {
      return email.responseTimeCategory === filter.replace('response', '').toLowerCase();
    }
    return true;
  });

  const importantEmails = filteredEmails.filter(email => email.priority === 'important');
  const otherEmails = filteredEmails.filter(email => email.priority === 'other');

  return (
    <div className="min-h-screen bg-gradient-to-br from-xylo-purple to-dark-bg text-white p-4 sm:p-8">
      {error ? (
        <div className="bg-alert text-white p-4 rounded-lg shadow-lg">
          <h2 className="text-xl font-bold mb-2">Error:</h2>
          <p>{error}</p>
        </div>
      ) : (
        <>
          <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-6 sm:mb-8">
            <h1 className="text-3xl sm:text-4xl font-bold text-white mb-4 sm:mb-0">Unread Emails</h1>
            <div className="flex flex-col sm:flex-row items-start sm:items-center space-y-4 sm:space-y-0 sm:space-x-6 w-full sm:w-auto">
              <span className="text-white text-lg font-semibold">
                Total Actions: {totalActions}
              </span>
              <div className="relative w-full sm:w-auto">
                <select
                  className="bg-xylo-purple text-white px-4 py-2 rounded-full appearance-none pr-10 focus:outline-none focus:ring-2 focus:ring-white w-full"
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                >
                  <option value="all">All Emails</option>
                  <option value="internal">Internal Only</option>
                  <option value="frequent">Frequent Contacts</option>
                  <option value="responseNow">Response Now</option>
                  <option value="responseToday">Response Today</option>
                  <option value="responseTomorrow">Response Tomorrow</option>
                  <option value="responseThisWeek">Response This Week</option>
                </select>
                <Filter size={18} className="absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none text-white" />
              </div>
            </div>
          </div>

          <section className="mb-8">
            <h2 className="text-xl sm:text-2xl font-semibold mb-4 text-white">Important ({importantEmails.length})</h2>
            <AnimatePresence>
              {importantEmails.map(email => (
                <EmailSummary key={email.id} email={email} />
              ))}
            </AnimatePresence>
          </section>

          <section>
            <div className="flex items-center mb-4">
              <h2 className="text-xl sm:text-2xl font-semibold text-white">Other ({otherEmails.length})</h2>
              <button
                className="ml-4 text-white hover:text-gray-light transition-colors duration-300 flex items-center"
                onClick={() => setShowOtherEmails(!showOtherEmails)}
              >
                {showOtherEmails ? (
                  <>Hide <ChevronUp size={20} className="ml-1" /></>
                ) : (
                  <>Show <ChevronDown size={20} className="ml-1" /></>
                )}
              </button>
            </div>
            <AnimatePresence>
              {showOtherEmails && otherEmails.map(email => (
                <EmailSummary key={email.id} email={email} />
              ))}
            </AnimatePresence>
          </section>
        </>
      )}
    </div>
  );
};

export default EmailPage;
