import { authenticatedRequest } from "./apiUtils";

export type UserMetric = {
  red_flag_count: number;
  red_flag_per_email_average: number;
  improvement_count: number;
  improvement_per_email_average: number;
};

export type UserMetricState = {
  last_24h: UserMetric;
  last_week: UserMetric;
};

export const getUserMetrics = async () => {
  try {
    const response = await authenticatedRequest(
      `/users/roi-metrics`,
      {
        method: "GET",
      }
    );
    // TODO: add validation to backend response
    // to ensure it matches the UserMetricState type
    return response as UserMetricState;
  } catch (error) {
    console.error("Error fetching user metrics", error);
  }
}
