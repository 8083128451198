import React, { useState } from 'react';

interface RedFlag {
  id: string;
  problem_text: string;
  reason: string;
}

const RedFlags: React.FC<{ redFlags: RedFlag[] }> = ({ redFlags }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % redFlags.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? redFlags.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="carousel-container">
      {redFlags.length > 0 && (
        <div className="red-flag-card bg-white/10 p-4 rounded-lg text-center">
          <p className="text-xylo-pink font-bold mb-2">
            Red Flag {currentIndex + 1} of {redFlags.length}
          </p>
          <p className="text-white font-bold mb-2">
            Problem: {redFlags[currentIndex].problem_text}
          </p>
          <p className="text-white">
            Reason: {redFlags[currentIndex].reason}
          </p>
        </div>
      )}
      <div className="carousel-controls flex justify-between mt-4">
        <button onClick={handlePrev} className="bg-xylo-pink text-white px-3 py-1 rounded">Previous</button>
        <button onClick={handleNext} className="bg-xylo-pink text-white px-3 py-1 rounded">Next</button>
      </div>
    </div>
  );
};

export default RedFlags;
