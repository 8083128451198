import React from 'react';

interface ToggleProps {
  checked: boolean;
  onChange: () => void;
  leftLabel: string;
  rightLabel: string;
}

const Toggle: React.FC<ToggleProps> = ({ checked, onChange, leftLabel, rightLabel }) => {
  return (
    <div className="flex items-center space-x-2">
      <span className={`text-sm ${!checked ? 'text-xylo-pink' : 'text-white/60'}`}>{leftLabel}</span>
      <button
        className={`w-14 h-7 flex items-center rounded-full p-1 ${
          checked ? 'bg-xylo-pink' : 'bg-white/10'
        }`}
        onClick={onChange}
      >
        <div
          className={`bg-white w-5 h-5 rounded-full shadow-md transform transition-transform ${
            checked ? 'translate-x-7' : ''
          }`}
        />
      </button>
      <span className={`text-sm ${checked ? 'text-xylo-pink' : 'text-white/60'}`}>{rightLabel}</span>
    </div>
  );
};

export default Toggle;