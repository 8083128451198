import React, { useState } from 'react';

const TipsCarousel: React.FC<{ tips: any[] }> = ({ tips }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % tips.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? tips.length - 1 : prevIndex - 1
    );
  };

  return (
    <div className="carousel-container">
      <h3 className="text-center text-lg font-bold mb-4">Contact Insights</h3>
      {tips.length > 0 && (
        <div className="tip-card bg-white/10 p-4 rounded-lg text-center">
          {/* Dynamically set the color of top_text using tips.light */}
          <p className="text-gray-400" style={{ color: tips[currentIndex].light }}>
            {tips[currentIndex].top_text}
          </p>
          <p>{tips[currentIndex].bottom_text}</p>
        </div>
      )}
      <div className="carousel-controls flex justify-between mt-4">
        <button onClick={handlePrev}>Previous</button>
        <button onClick={handleNext}>Next</button>
      </div>
    </div>
  );
};

export default TipsCarousel;
