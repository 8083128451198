import React from 'react';

interface Improvement {
  id: string;
  original_text: string;
  text: string;
  reason: string;
}

const ImprovementsCarousel: React.FC<{ improvements: Improvement[] }> = ({ improvements }) => {
  return (
    <div className="improvements-list">
      <div className="max-h-64 overflow-y-auto">
        {improvements.map((improvement, index) => (
          <div key={improvement.id} className="improvement-card bg-white/10 p-4 rounded-lg mb-4">
            <p className="font-bold text-xylo-pink mb-2">Improvement {index + 1} of {improvements.length}</p>
            <p className="font-bold">Original Text:</p>
            <p className="text-red-400 mb-2">{improvement.original_text}</p>

            <p className="font-bold">Modified Text:</p>
            <p className="text-green-400 mb-2">{improvement.text}</p>

            <p className="font-bold">Reason for Change:</p>
            <p>{improvement.reason}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImprovementsCarousel;
