const backendURL = process.env.REACT_APP_URL || "https://app.dev.xylo.ai";

const config = {
  backendURL,
  apiURL: backendURL + "/api",
  backendHost: process.env.REACT_APP_BACKEND_HOST || "app.dev.xylo.ai",
  clientId: process.env.REACT_APP_CLIENT_ID || "61ee4e12-50a0-4df2-8191-498e73bb5afa",
  appURL: process.env.REACT_APP_URL || "https://devweb.xylo.ai"
};

export default config;
