import { authenticatedRequest } from "./apiUtils";
import config from "../config";

export const fetchUserId = async () => {
  try {
    const data = await authenticatedRequest('/users/id', {
      method: "GET",
    });

    return data;
  } catch (error) {
    console.error("Fetch Error:", error);
    return false; // Handle fetch error appropriately
  }
};

export const fetchUserDetails = async (userId: number) => {
  try {
    const data = await authenticatedRequest(`/users/${userId}`, {
      method: "GET",
    });

    return data;
  } catch (error) {
    console.error("Fetch Error:", error);
    return false; // Handle fetch error appropriately
  }
};

export const fetchLicenses = async (tenantId: string | null) => {
  try {
    const baseURL = config.backendURL;
    if (!baseURL) {
      throw new Error("REACT_APP_URL is not defined");
    }

    const endpoint = `/licenses`;

    const url = new URL(endpoint, baseURL);
    if (tenantId) {
      url.searchParams.append('tenant_id', tenantId);
    }

    const data = await authenticatedRequest(url.pathname + url.search, {
      method: "GET"
    }, baseURL);

    return data;
  } catch (error) {
    console.error("Fetch Error:", error);
    return false; // Handle fetch error appropriately
  }
};
