import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { Mic, RefreshCw, Send, StopCircle } from 'react-feather';
import SpeechToText from '../speech';
import { useWebsocket } from '../_hooks/use-websocket';
import { stripMarkdown } from '../_helpers/strip-markdown';
import TipsCarousel from '../components/TipsCarousel/TipsCarousel';
import ImprovementsCarousel from '../components/ImprovementsCarousel/ImprovementsCarousel';
import RedFlags from '../components/RedFlags';
import Toggle from '../components/Toggle';

const AiDraftPage: React.FC = () => {
  const [inputText, setInputText] = useState('');
  const [outputText, setOutputText] = useState('');
  const [mode, setMode] = useState<'rewrite' | 'quickDraft'>('rewrite');
  const [tonePreference, setTonePreference] = useState<'Professional' | 'Casual'>('Professional');
  const [isListening, setIsListening] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [inputSubject, setInputSubject] = useState('');
  const [outputSubject, setOutputSubject] = useState('');
  const [contact, setContact] = useState('');
  const [communicationTips, setCommunicationTips] = useState<any[]>([]);
  const [contactQuadrantCompleted, setContactQuadrantCompleted] = useState(false);
  const [userQuadrantCompleted, setUserQuadrantCompleted] = useState(false);
  const [rewriteImprovements, setRewriteImprovements] = useState<any[]>([]);
  const [redFlags, setRedFlags] = useState<any[]>([]);
  const [isEmail, setIsEmail] = useState(false);
  const speechToTextRef = useRef<SpeechToText | null>(null);
  const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const { on, sendMessage } = useWebsocket();

  useEffect(() => {
    speechToTextRef.current = new SpeechToText(
      (transcript) => setInputText(prevText => prevText + formatTranscript(transcript)),
      (status) => setIsListening(status)
    );

    on(["write_from_description_stream"], (message) => {
      const payload = message.payload || message.response;
      setOutputText(stripMarkdown(payload.accumulated_text));
      setIsGenerating(false);
    });

    on(["write_subject_from_description"], (message) => {
      const payload = message.payload || message.response;
      setOutputSubject(stripMarkdown(payload.text));
      setIsGenerating(false);
    });

    on(["rewrite_body_stream"], (message) => {
      const payload = message.payload || message.response;
      setOutputText(stripMarkdown(payload.accumulated_text));
      setIsGenerating(false);
    });

    on(["rewrite_subject"], (message) => {
      const payload = message.payload || message.response;
      setOutputSubject(stripMarkdown(payload.text));
      setIsGenerating(false);
    });

    on(["rewrite_modification"], (message) => {
      const payload = message.payload || message.response;
      setRewriteImprovements((prevImprovements) => [...prevImprovements, payload]);
    });

    on(["contact_communication_quadrant"], (message) => {
      console.log("Contact quadrant completed", message.payload);
      setContactQuadrantCompleted(true);
    });

    on(["user_communication_quadrant"], (message) => {
      console.log("User quadrant completed", message.payload);
      setUserQuadrantCompleted(true);
    });

    on(["communication_tips"], (message) => {
      const tips = message.payload.tips || [];
      console.log("Received tips:", tips);
      setCommunicationTips(tips);
    });

    on(["analyze_red_flags"], (message) => {
      const payload = message.payload || {};
      const red_flags = payload.red_flags || [];
      console.log("Email analysis completed", payload);
      setRedFlags(red_flags);
    });

    return () => {
      // Clean up websocket listeners if necessary
    };
  }, [on]);

  const formatTranscript = (transcript: string) => {
    // Add space between sentences
    const formattedTranscript = transcript.replace(/([.!?])\s*(?=[A-Z])/g, '$1 ');
    // Add line breaks for longer pauses
    return formattedTranscript.replace(/(\S)\s{2,}(\S)/g, '$1\n\n$2');
  };

  useEffect(() => {
    if (contactQuadrantCompleted && userQuadrantCompleted) {
      console.log("Both quadrants are completed, triggering request for tips...");
      sendMessage({
        message_type: "get_communication_tips",
        payload: { contact },
      });
    }
  }, [contactQuadrantCompleted, userQuadrantCompleted, contact, sendMessage]);

  const handleContactChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newContact = e.target.value;
    setContact(newContact);

    setContactQuadrantCompleted(false);
    setUserQuadrantCompleted(false);
    setCommunicationTips([]);

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    debounceTimeoutRef.current = setTimeout(() => {
      if (emailRegex.test(newContact)) {
        sendMessage({
          message_type: "get_contact_data",
          payload: { contact: newContact },
        });
      }
    }, 2000);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInputText(e.target.value);
  };

  const handleModeChange = (newMode: 'rewrite' | 'quickDraft') => {
    setMode(newMode);
  };

  const handleToneToggle = () => {
    setTonePreference((prevTone) =>
      prevTone === 'Professional' ? 'Casual' : 'Professional'
    );
  };

  const handleEmailToggle = () => {
    setIsEmail((prev) => !prev);
  };

  const handleSubmit = async () => {
    setIsGenerating(true);
    setOutputText('');
    setOutputSubject('');
    setRewriteImprovements([]);
    setRedFlags([]);

    if (mode === 'rewrite' && inputText) {
      await sendMessage({
        message_type: "analyze_email",
        payload: {
          message: inputText,
          contact: contact,
          subject: inputSubject,
        },
      });
    }

    const payload = mode === 'quickDraft'
      ? {
        description: inputText,
        tone_preference: tonePreference
      }
      : {
        message: inputText,
        contact: contact,
        tone_preference: tonePreference,
        subject: inputSubject,
      };

    const messageType = mode === 'quickDraft'
      ? "write_from_description"
      : "rewrite_email";

    console.log("Sending message:", messageType);
    await sendMessage({
      message_type: messageType,
      payload,
    });
  };

  const handleSpeechToText = () => {
    if (isListening) {
      speechToTextRef.current?.stop();
      setIsListening(false);
    } else {
      speechToTextRef.current?.start();
      setIsListening(true);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-xylo-purple to-dark-bg text-white p-4 md:p-8">
      <h1 className="text-3xl md:text-4xl font-bold mb-6 md:mb-8">AI Draft</h1>

      {/* Mode Selection */}
      <section className="mb-6 md:mb-8">
        <div className="flex space-x-4 items-center">
          <button
            className={`px-4 py-2 rounded-full ${mode === 'rewrite' ? 'bg-xylo-pink' : 'bg-white/10'}`}
            onClick={() => handleModeChange('rewrite')}
          >
            Rewrite
          </button>
          <button
            className={`px-4 py-2 rounded-full ${mode === 'quickDraft' ? 'bg-xylo-pink' : 'bg-white/10'}`}
            onClick={() => handleModeChange('quickDraft')}
          >
            Quick Draft
          </button>
        </div>
      </section>

      {/* Input Section */}
      <section className="mb-6 md:mb-8">
        <div className="space-y-4">
          <div className="flex justify-between items-center">
            <Toggle
              checked={tonePreference === 'Professional'}
              onChange={handleToneToggle}
              leftLabel="Casual"
              rightLabel="Professional"
            />
            <Toggle
              checked={isEmail}
              onChange={handleEmailToggle}
              leftLabel="Text"
              rightLabel="Email"
            />
          </div>
          {mode === 'rewrite' && isEmail && (
            <>
              <input
                type="text"
                className="w-full bg-white/10 text-white rounded-lg p-4 mb-4"
                value={contact}
                onChange={handleContactChange}
                placeholder="Enter contact email..."
              />
              <input
                type="text"
                className="w-full bg-white/10 text-white rounded-lg p-4 mb-4"
                value={inputSubject}
                onChange={(e) => setInputSubject(e.target.value)}
                placeholder="Enter subject..."
              />
            </>
          )}

          <textarea
            className="w-full h-64 bg-white/10 text-white rounded-lg p-4 resize-none"
            value={inputText}
            onChange={handleInputChange}
            placeholder={mode === 'rewrite' ? "Enter text to rewrite..." : "Enter your quick draft prompt..."}
          />
          <div className="flex justify-end items-center space-x-2">
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className={`bg-white/10 p-2 rounded-full ${isListening ? 'text-red-500' : ''}`}
              onClick={handleSpeechToText}
            >
              {isListening ? <StopCircle size={20} /> : <Mic size={20} />}
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="bg-xylo-pink text-white px-4 py-2 rounded-full font-medium flex items-center"
              onClick={handleSubmit}
              disabled={isGenerating}
            >
              {isGenerating ? (
                <RefreshCw size={20} className="mr-2 animate-spin" />
              ) : mode === 'rewrite' ? (
                <RefreshCw size={20} className="mr-2" />
              ) : (
                <Send size={20} className="mr-2" />
              )}
              {isGenerating ? 'Generating...' : mode === 'rewrite' ? 'Rewrite' : 'Generate'}
            </motion.button>
          </div>
        </div>

        {communicationTips.length > 0 && (
          <div className="mt-4">
            <TipsCarousel tips={communicationTips} />
          </div>
        )}
      </section>

      {/* Output Section */}
      <section className="mb-6 md:mb-8">
        <div className="bg-white/10 rounded-lg p-6">
          <h2 className="text-2xl font-semibold mb-4">Output</h2>
          {isEmail && (
            <div className="bg-white/5 rounded-lg p-4 h-14 mb-4">
              {outputSubject || "AI-generated subject will appear here..."}
            </div>
          )}
          <div className="bg-white/5 rounded-lg p-4 h-64 overflow-auto">
            {outputText || "AI-generated content will appear here..."}
          </div>
        </div>
      </section>

      {/* Improvements and Red Flags Section */}
      <section className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-8">
        <div>
          {rewriteImprovements.length > 0 && (
            <div className="bg-white/10 rounded-lg p-6 h-full">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-semibold">Improvements</h2>
                <span className="text-lg font-semibold">{rewriteImprovements.length}</span>
              </div>
              <ImprovementsCarousel improvements={rewriteImprovements} />
            </div>
          )}
        </div>
        <div>
          {redFlags.length > 0 && (
            <div className="bg-white/10 rounded-lg p-6 h-full">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-2xl font-semibold">Red Flags</h2>
                <span className="text-lg font-semibold">{redFlags.length}</span>
              </div>
              <RedFlags redFlags={redFlags} />
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default AiDraftPage;
