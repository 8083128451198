import React, { createContext, useContext, useState, useCallback } from "react";
import {
  fetchUserId,
  fetchUserDetails,
  fetchLicenses,
} from "./api";
import { handleAuthRedirect } from "../components/auth/microsoft-auth";

interface StateManagerContextType {
  state: {
    userId: null | string | void;
    userDetails: any;
    tenantId: string | null;
    setTenantId: React.Dispatch<React.SetStateAction<string | null>>;
    licenses: any;
    setLicenses: any;
    isSwitchLoading: boolean;
    setIsSwitchLoading: React.Dispatch<React.SetStateAction<boolean>>;
  };
  logout: () => void;
}

const StateManagerContext = createContext<StateManagerContextType | undefined>(
  undefined
);

export const StateManagerProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [userId, setUserId] = useState<null | string | void>(null);
  const [userDetails, setUserDetails] = useState<any>(null);
  const [tenantId, setTenantId] = useState<string | null>(null);
  const [licenses, setLicenses] = useState<any>(null);
  const [isSwitchLoading, setIsSwitchLoading] = useState(false);

  const logout = useCallback(() => {
    setUserId(null);
    setUserDetails(null);
    setTenantId(null);
    setLicenses(null);
    setIsSwitchLoading(false);
    // Add any other state resets here
  }, []);

  const [initialLoad, setInitialLoad] = useState(true);

  React.useEffect(() => {
    const fetchData = async (userId: any) => {
      const userDetails = await fetchUserDetails(userId.user_id);
      setUserId(userId.user_id);
      setUserDetails(userDetails);
      
      if (userDetails && userDetails.tenantId) {
        setTenantId(userDetails.tenantId);
        // Fetch licenses after setting the tenantId
        const licenses = await fetchLicenses(userDetails.tenantId);
        setLicenses(licenses);
      } else {
        console.error("TenantId not found in user details");
        // Handle the case where tenantId is not available
      }
    };

    const call = async () => {
      try {
        const userId = await fetchUserId();

        if (userId) {
          await fetchData(userId);
        } else {
          try {
            const data = await handleAuthRedirect();
            if (!data) {
              console.log("Auth redirect failed");
              // Handle failed auth redirect
            }
            const userIdAfterRedirect = await fetchUserId();
            if (userIdAfterRedirect) {
              await fetchData(userIdAfterRedirect);
            } else {
              console.log("Failed to get user ID after redirect");
              // Handle failed user ID fetch
            }
          } catch (err) {
            console.error("Error during auth redirect:", err);
            // Handle auth redirect error
          }
        }
      } catch (error) {
        console.error("An error occurred:", error);
      } finally {
        setInitialLoad(false);
      }
    };

    if (initialLoad) {
      call();
    }
  }, [initialLoad]);

  const state = {
    userId,
    userDetails,
    tenantId,
    setTenantId,
    licenses,
    setLicenses,
    isSwitchLoading,
    setIsSwitchLoading,
  };

  return (
    <StateManagerContext.Provider value={{ state, logout }}>
      {children}
    </StateManagerContext.Provider>
  );
};

export const useStateManager = () => {
  const context = useContext(StateManagerContext);
  if (!context) {
    throw new Error(
      "useStateManager must be used within a StateManagerProvider"
    );
  }
  return context;
};
