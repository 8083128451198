import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import config from './config';

const backendHost = config.backendHost;
const websocketUrl = `wss://${backendHost}`;
const apiUrl = config.backendURL;

const setCSPMetaTag = (wsUrl: string, apiUrl: string) => {
  const metaTag = document.createElement('meta');
  metaTag.httpEquiv = 'Content-Security-Policy';
  metaTag.content = `
    default-src 'self'; 
    script-src 'self' 'unsafe-inline' 'unsafe-eval'; 
    style-src 'self' 'unsafe-inline'; 
    connect-src 'self' ${wsUrl} ${apiUrl}; 
    img-src 'self' data: https:; 
    font-src 'self' https: data:;`;
  document.head.appendChild(metaTag);
};

// Add the meta tag before the app renders
setCSPMetaTag(websocketUrl, apiUrl);


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

reportWebVitals();
