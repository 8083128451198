import Cookies from 'js-cookie';

const defaultCookieOptions = {
  sameSite: 'None',
  secure: true,
} as const;

const MAX_REFRESH_TOKEN_DURATION_IN_DAYS = 14;

export const cookieStore = {
  set: (key: string, value: string) => {
    Cookies.set(key, value, {
      ...defaultCookieOptions,
      expires: MAX_REFRESH_TOKEN_DURATION_IN_DAYS,
    });
  },
  get: (key: string) => {
    return Cookies.get(key);
  },
  remove: (key: string) => {
    Cookies.remove(key, defaultCookieOptions);
  },
} as const;

export const authCookieStore = {
  save: (accessToken: string, refreshToken: string) => {
    Cookies.set('access_token', accessToken, { secure: true, sameSite: 'strict' });
    Cookies.set('refresh_token', refreshToken, { secure: true, sameSite: 'strict' });
  },
  getToken: () => Cookies.get('access_token') || null,
  getRefreshToken: () => Cookies.get('refresh_token') || null,
  clear: () => {
    Cookies.remove('access_token');
    Cookies.remove('refresh_token');
  },
} as const;


